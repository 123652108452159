<template>
    <div class="image_item" @mouseover="maskShow = true" @mouseleave="maskShow = false">
        <a-icon type="delete" style="color:red;font-size:20px;"  @click.stop="deleteImage" :class="maskShow?'mask':'mask_hide'" @mouseover="mouseover" @mouseleave="mouseleave" />
        <viewer>
            <img :src="url" alt="">
        </viewer>
    </div>
</template>

<script>
export default {
  props: ['url', 'index'],
  data () {
    return {
      maskShow: false
    }
  },
  methods: {
    deleteImage () {
      this.$emit('deleteImage', this.index)
    },
    mouseover () {
      this.$emit('mouseover')
    },
    mouseleave () {
      this.$emit('mouseleave')
    }
  }
}
</script>

<style lang="scss" scoped>
.image_item {
    cursor: pointer;
    position: relative;
    width: 105px;
    height: 105px;
    border: 1px solid rgb(200, 200, 200);
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%
    }

}
    .mask {
        position: absolute;
        top:3px;
        right:3px;
    }

    .mask_hide {
        display: none;
    }
</style>
