<template>
    <div>
        <a-modal :title="title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" :width="1400"
            @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    返回
                </a-button>
                <a-button v-if="editingGroup !== 'edit'" key="submit" type="primary" :loading="confirmLoading"
                    @click="handleOk">
                    确认
                </a-button>
            </template>
            <!-- 我的图片 -->
            <div v-if="status === 1" class="my_images">
                <div class="my_images_left">
                    <div style="margin-bottom:10px;">
                        <a-button @click="uploadImages">
                            上传图片
                        </a-button>
                        <a href="#" style="margin-left:10px;">前往素材中心</a>
                    </div>
                    <div style="margin-bottom:10px;">
                        <a-input-search placeholder="搜索分组名称" @search="onSearch" />
                    </div>
                    <ul>
                        <li :class="`image_group_item ${item.active}`" v-for="(item, index ) in imageGroupList"
                            :key="index" @click="selectGroupToShowImages(index)">
                            <div>{{ item.groupName }}</div>
                            <div>{{ item.qty }}</div>
                        </li>
                    </ul>
                </div>
                <div class="my_images_right">
                    <!-- 已选择的图片 -->
                    <div style="display: flex;flex-wrap:wrap;width:100%; min-height: 100px;">
                        <template v-if="imageLength !== 0 && typeof showSelectedImages === 'object'">
                            <draggable
                                :disabled="canDrag"
                                class="image_item_wrap"
                                v-model="showSelectedImages"
                                handle=".item"
                                chosenClass="chosen" forceFallback="true" group="people" animation="200"
                                >
                                <div class="item" v-for="(item, index) in showSelectedImages" :key="index">
                                    <ImageItem :url="item" :index="index" @deleteImage="deleteSelectedImage" @mouseover="canDrag=true" @mouseleave="canDrag=false"></ImageItem>
                                </div>
                            </draggable>
                        </template>
                        <template v-else-if="imageLength ===0">
                            <div v-if="showSelectedImages != ''" class="image_item_wrap">
                                <ImageItem :url="showSelectedImages"  @deleteImage="deleteSelectedImage" :index="0" @mouseover="canDrag=true" @mouseleave="canDrag=false"></ImageItem>
                            </div>
                        </template>
                    </div>

                    <a-divider></a-divider>

                    <!-- 待选择的图片 -->
                    <div class="image_item_wrap">
                        <div class="image_item" v-for="(imgItem, imgIndex) in imagesOfGroup" :key="imgIndex"
                            @click="clickedImage(imgIndex)">
                            <div v-if="imgItem.selected"
                                style="width:100%;height:100%;position: absolute; top:0;right:0;background:rgba(42, 150, 28, 0.6)">
                                <!-- <a-icon type="check-square" style="color:#fff;font-size:20px;" /> -->
                            </div>
                            <img :src="imgItem.imgUrl" alt="">
                            <div v-if="showWidth" :class="imgItem.widthHeightFit?'w_h_fit':''" style="position:absolute;right:0;bottom:0;color:white;font-size:8px;line-height:14px;background:rgba(0,0,0,0.5);">
                                {{imgItem.width}}-
                                {{imgItem.height}}-
                                {{Math.floor(imgItem.size / 1024) === 0 ? imgItem.size+'B' : Math.floor(imgItem.size / 1024)+'K'}}
                            </div>
                        </div>
                    </div>
                    <a-pagination show-size-changer v-model="pagination.pageNo" :total="pagination.total" :page-size="pagination.pageSize" :page-size-options="pagination.pageSizeOptions"
                        :show-total="(total, range) => `共${total}张，每页${pagination.pageSize}张`"
                        @change="loadList()" @showSizeChange="onShowSizeChange" />
                </div>
            </div>
            <!-- 上传图片 -->
            <div v-else class="upload_images">
                <a-form-model ref="ruleForm" :model="form" :rules="rules" :labelCol="labelCol">
                    <a-form-model-item v-if="editingGroup === 'select'" :wrapperCol="wrapperCol" label="上传方式"
                        prop="loadType">
                        <a-radio-group v-model="form.loadType">
                            <a-radio value="local">
                                本地上传
                            </a-radio>
                            <a-radio value="scan">
                                扫码上传
                            </a-radio>
                            <a-radio value="online">
                                网络图片
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item :wrapperCol="wrapperCol" label="所在分组"
                        :prop="editingGroup === 'add' ? 'editingGroupName' : 'group'">
                        <div v-if="editingGroup === 'edit'">
                            <div v-for="(item, index ) in imageGroupList" :key="index">
                                <a-input v-model="item.groupName" style="width:200px;" placeholder="请输入分组名" />
                                <a-input-number style="width:100px; margin-left:10px;" v-model="item.sort"
                                    placeholder="排序号" :min="1" :max="1000" />
                                <a-popconfirm title="确定要删除此项吗?" ok-text="是" cancel-text="否" @confirm="confirm(item)"
                                    @cancel="cancel">
                                    <a-tag style="margin-left:10px; cursor:pointer;" color="#f50">
                                        删除
                                    </a-tag>
                                </a-popconfirm>

                                <a-tag style=" cursor:pointer;" color="#108ee9" @click="editGroup(item)">
                                    修改
                                </a-tag>
                                <a-button v-if="index === imageGroupList.length - 1" style="margin-left:10px;"
                                    @click="editingGroup = 'select'; $refs.ruleForm.clearValidate(['editingGroupName'])">
                                    返回
                                </a-button>
                            </div>
                        </div>
                        <div v-if="editingGroup === 'add'">
                            <div>
                                <a-input v-model="form.editingGroupName" style="width:200px;" placeholder="请输入分组名" />
                                <a-input-number style="width:100px; margin-left:10px;" v-model="form.groupSort"
                                    placeholder="排序号" :min="1" :max="1000"
                                    @change="$refs.ruleForm.validateField(['editingGroupName'])" />
                                <a-tag style="margin-left:10px; cursor:pointer;" color="#f50"
                                    @click="editingGroup = 'select'; $refs.ruleForm.clearValidate(['editingGroupName'])">
                                    返回
                                </a-tag>
                                <a-tag style="cursor:pointer;" type="primary" color="#108ee9" @click="addGroup">
                                    确认
                                </a-tag>
                            </div>
                        </div>
                        <div v-if="editingGroup === 'select'">
                            <a-select v-model="form.group" placeholder="请选择分组" style="width:200px;"
                                @change="$refs.ruleForm.validateField(['group'])">
                                <a-select-option v-for="(item, index ) in imageGroupList" :key="index" :value="item.id">
                                    <div>{{ item.groupName }}</div>
                                </a-select-option>
                            </a-select>
                            <a-tag style="margin-left:10px; cursor:pointer;" color="#108ee9"
                                @click="editingGroup = 'edit'">
                                编辑
                            </a-tag>
                            <a-tag style="cursor:pointer;" color="#108ee9" @click="editingGroup = 'add'">
                                添加
                            </a-tag>
                        </div>
                    </a-form-model-item>

                    <a-form-model-item v-if="editingGroup === 'select'" :wrapperCol="wrapperCol" label="本地图片"
                        prop="fileList">
                        <a-upload :multiple="true" list-type="picture-card" :file-list="form.fileList"
                            @preview="handlePreview" :action="$consts.uploadFileUrl" :headers="$store.state.header"
                            :before-upload="beforeUpload" @change="handleChange">
                            <div>
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                    Upload
                                </div>
                            </div>
                        </a-upload>
                    </a-form-model-item>
                </a-form-model>

            </div>
        </a-modal>

        <!-- 按钮与 图片展示区 -->
        <div v-if="preview" style="display: flex;flex-wrap:wrap;width:100%;">
            <!-- 图片展示 -->
            <template v-if="imageLength !== 0 && typeof myValue === 'object'">
                <draggable
                    :disabled="canDrag"
                    class="image_item_wrap"
                    v-model="draggAbbleModel"
                    handle=".item"
                    chosenClass="chosen" forceFallback="true" group="people" animation="200"
                    @end="onEnd">
                    <div class="item" v-for="(item, index) in myValue" :key="index">
                        <ImageItem :url="item" :index="index" @deleteImage="deleteImage" @mouseover="canDrag=true" @mouseleave="canDrag=false"></ImageItem>
                    </div>
                    <Button v-if="imageLength === -1" @click.native="showModal"></Button>
                    <Button v-else-if="myValue.length < imageLength" @click.native="showModal"></Button>
                </draggable>
            </template>
            <template v-else-if="imageLength ===0">
                <div v-if="myValue != ''" class="image_item_wrap">
                    <ImageItem :url="myValue" @deleteImage="deleteImage" :index="0" @mouseover="canDrag=true" @mouseleave="canDrag=false"></ImageItem>
                </div>
                <Button v-if="myValue.length ===0" @click.native="showModal"></Button>
            </template>
        </div>

        <!-- 建议图片800*450，jpg,png,gif，单张图片大小不超过2MB -->
        <div style="color:#aaaaaa;line-height:18px;margin-bottom:10px;">
            <slot name="extra" style="color:red;"></slot>
        </div>

    </div>
</template>

<script>
import Button from './uploadButton.vue'
import ImageItem from './imgItem.vue'
import draggable from 'vuedraggable'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: { Button, draggable, ImageItem },
  props: {
    // 限制图片的大小
    size: {
      type: Number,
      default: 1024 * 1024
    },
    // 限制图片的大小
    width: {
      type: Number,
      default: -1
    },
    // 限制图片的大小
    height: {
      type: Number,
      default: -1
    },
    // 是否1：1
    square: {
      type: Boolean,
      default: false
    },
    // 是否1：1
    showWidth: {
      type: Boolean,
      default: true
    },
    // 是否绑定单图
    imageLength: {
      type: Number,
      default: 0
    },
    preview: {
      type: Boolean,
      default: true
    },
    myValue: {
      type: [String, Array],
      default: ''
    }
  },
  model: {
    prop: 'myValue', // 默认是value
    event: 'myInput' // 默认是input
  },
  watch: {
    visible (newVal) {
      let val = this.myValue
      this.showSelectedImages = this.$utils.clone(val)
    },
    myValue (newVal) {
      this.draggAbbleModel = this.$utils.clone(newVal)
    }
  },
  data () {
    let checkEditingGroupNameAndSort = (rule, value, callback) => {
      const { editingGroupName, groupSort } = this.form
      if (!editingGroupName) {
        return callback(new Error('请输入分组名'))
      }
      if (!groupSort) {
        return callback(new Error('请输入分组排序'))
      }
      return callback()
    }
    return {
      canDrag: false,
      draggAbbleModel: undefined,
      showSelectedImages: [],
      visible: false,
      confirmLoading: false,
      status: 1, // 1表示我的图片  2表示 上传图片
      title: '我的图片',
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        pageNo: 1,
        pageSize: 40,
        total: 0
      },
      imageGroupList: [], // 分组列表
      imagesOfGroup: [], // 某个分组下的所有图片
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      editingGroup: 'select', // select 选择分组  edit 编辑分组   add 添加分组
      form: {
        loadType: 'local', // local:本地  online:网络图片  scan:扫码上传
        group: undefined, // 默认未分组
        editingGroupName: '',
        groupSort: undefined,
        fileList: []
      },
      rules: {
        name: [
          { required: true, message: 'Please input Activity name', trigger: 'blur' },
          { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        group: [
          { required: true, message: '请选择分组', trigger: 'change' }
        ],
        fileList: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        editingGroupName: [
          { required: true, message: '请输入分组名与排序号', trigger: 'blur' },
          { validator: checkEditingGroupNameAndSort, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 假删除 选中的图片
    deleteSelectedImage (e) {
      try {
        this.showSelectedImages.splice(e, 1)
      } catch (error) {
        this.showSelectedImages = ''
      }

      this.setSelected()
    },
    // 删除 v-model中的图片
    deleteImage (e) {
      if (this.imageLength === 0) {
        this.$emit('myInput', '')
        this.$emit('change', '')
      } else {
        console.log(e, 'eeee')
        let data = this.$utils.clone(this.myValue)
        data.splice(e, 1)
        this.$emit('myInput', data)
        this.$emit('change', data)
      }
    },
    onEnd (e) {
      this.$emit('myInput', this.draggAbbleModel)
    },
    sort (e) {
      console.log(e, 'eee')
    },
    // params: url
    // result : {width,height}
    async getImageSize (imgUrl) {
      return new Promise((resolve, reject) => {
        var img = new Image()
        img.src = imgUrl
        img.onload = function () {
          let imgWidth = img.width || null
          let imgHeight = img.height || null
          return resolve({ width: imgWidth, height: imgHeight })
        }
      })
    },
    async checkSquare (imgUrl) {
      let imgInfo = await this.getImageSize(imgUrl)
      return new Promise((resolve, reject) => {
        if (!this.square) {
          return resolve({ checked: true })
        }
        if (imgInfo && imgInfo.width && imgInfo.width === imgInfo.height) {
          return resolve({ checked: true })
        } else {
          return resolve({ checked: false, message: '请上传正方形图片' })
        }
      })
    },
    // params 预期宽度 实际宽度
    checkWidth (wantedWidth, realWidth) {
      if (wantedWidth === -1) {
        return { checked: true }
      }
      if (wantedWidth === realWidth) {
        return { checked: true }
      }
      if (wantedWidth !== realWidth) {
        return { checked: false, message: '图片宽度不对' }
      }
    },
    // params 预期高度 实际高度
    checkHeight (wantedHeight, realHeight) {
      if (wantedHeight === -1) {
        return { checked: true }
      }
      if (wantedHeight === realHeight) {
        return { checked: true }
      }
      if (wantedHeight !== realHeight) {
        return { checked: false, message: '图片高度不对' }
      }
    },
    checkImageSize (size) {
      let checked = false
      let message = ''
      if (size > this.size) {
        checked = true
        message = '您的图片超大了'
      }
      return { checked, message }
    },
    async clickedImage (index) {
      //   检测图片是否符合宽度
      let checkHeight = this.checkHeight(this.height, this.imagesOfGroup[index].height)
      if (!checkHeight.checked) {
        this.$store.dispatch('showToast', {
          type: 'error', message: checkHeight.message
        })
        return
      }

      //   检测图片是否符合宽度
      let checkWidth = this.checkWidth(this.width, this.imagesOfGroup[index].width)
      if (!checkWidth.checked) {
        this.$store.dispatch('showToast', {
          type: 'error', message: checkWidth.message
        })
        return
      }

      //   检测图片是否超大
      let checkedSquare = await this.checkSquare(this.imagesOfGroup[index].imgUrl)
      if (!checkedSquare.checked) {
        this.$store.dispatch('showToast', {
          type: 'error', message: checkedSquare.message
        })
        return
      }

      //   检测图片是否超大
      let checkedSize = this.checkImageSize(this.imagesOfGroup[index].size)
      if (checkedSize.checked) {
        this.$store.dispatch('showToast', {
          type: 'error', message: checkedSize.message
        })
        return
      }

      // 如果是单选图片
      if (this.imageLength === 0) {
        // this.imagesOfGroup.forEach(element => {
        //   element.selected = false
        // })
        this.showSelectedImages = this.imagesOfGroup[index].imgUrl
        this.setSelected()
      }
      if (this.imageLength === -1) {
        if (this.showSelectedImages.indexOf(this.imagesOfGroup[index].imgUrl) === -1) {
          this.showSelectedImages.push(this.imagesOfGroup[index].imgUrl)
          this.setSelected()
        }
      }

      if (this.imageLength > 0 && this.showSelectedImages.length < this.imageLength) {
        if (this.showSelectedImages.indexOf(this.imagesOfGroup[index].imgUrl) === -1) {
          this.showSelectedImages.push(this.imagesOfGroup[index].imgUrl)
          this.setSelected()
        }
      }

      //   this.imagesOfGroup[index].selected = !this.imagesOfGroup[index].selected
    },
    onShowSizeChange (current, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.pageNo = 1
      this.loadList()
    },
    async editGroup (item) {
      let params = {
        id: item.id,
        groupName: item.groupName,
        sort: item.sort
      }
      await this.$store.dispatch('http', {
        api: 'resourceGroupEdit',
        params,
        success: (e) => {
          this.$store.dispatch('showToast', {
            type: 'success',
            message: '编辑成功'
          })
          this.getImageGroup()
        },
        error: (e) => {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: e
          })
        }
      })
    },
    async confirm (item) {
      await this.$store.dispatch('http', {
        api: 'resourceGroupDelete',
        query: {
          id: item.id
        },
        success: (e) => {
          this.$store.dispatch('showToast', {
            type: 'success',
            message: '删除成功'
          })
          this.getImageGroup()
        },
        error: (e) => {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: e
          })
        }
      })
    },
    cancel () { },
    async addGroup () {
      this.$refs.ruleForm.validateField(['editingGroupName'], async error => {
        if (!error) {
          const { editingGroupName, groupSort } = this.form
          let params = {
            groupName: editingGroupName,
            sort: groupSort
          }
          await this.$store.dispatch('http', {
            api: 'resourceGroupAdd',
            params,
            success: (e) => {
              this.$store.dispatch('showToast', {
                type: 'success',
                message: '分组添加成功'
              })
              this.getImageGroup()
              this.form.editingGroupName = ''
              this.form.groupSort = undefined
            },
            error: (e) => {
              this.$store.dispatch('showToast', {
                type: 'error',
                message: e
              })
            }
          })
        }
      })
    },
    showModal () {
      this.switchFun(1)
      this.visible = true
    },
    handleChange ({ fileList }) {
      this.form.fileList = fileList
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' // 这里获取上传文件的类型
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '图片格式不正确'
          })
          return reject(new Error(0)) // 如有问题那咱们通过reject 函数传一个false阻止他的上传
        }
        if (isJpgOrPng) {
          let reader = new FileReader() // 这个是通过原生的方式来获取图片的宽高
          reader.readAsDataURL(file)
          reader.onload = () => {
            const image = new Image()
            image.src = reader.result
            image.onload = () => {
              if (file.size > 500 * 1024) { // 102400
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: '图片大小不能超过500K,建议200k以内'
                })
                return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              } else {
                return resolve(true) // 如没有问题了那咱们就通过resolve函数添加一个true 让文件上传
              }
            }
          }
        }
      })
    },
    handleOk (e) {
      //   console.log('点击了确定按钮')
      //   this.confirmLoading = true
      //   setTimeout(() => {
      //     this.visible = false
      //     this.confirmLoading = false
      //   }, 2000)
      // 上传图片
      if (this.status === 2) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.addGroupResource()
          } else {
            return false
          }
        })
      } else {
        // let selectedImages = []
        // this.imagesOfGroup.forEach(element => {
        //   if (element.selected) {
        //     selectedImages.push(element.imgUrl)
        //   }
        // })
        // if (this.imageLength === 0) {
        //   this.$emit('myInput', selectedImages[0])
        // } else {
        //   this.$emit('myInput', selectedImages)
        // }
        this.$emit('myInput', this.showSelectedImages)
        this.$emit('change', this.showSelectedImages)
        this.visible = false
      }
    },
    async addGroupResource () {
      let resourceGroupId = this.form.group
      let resourceList = []
      this.form.fileList.forEach(element => {
        let obj = {}
        obj.resourceType = element.type.split('/')[0]
        obj.imgUrl = element.response.result
        obj.size = element.size
        obj.resourceInfo = element.type.split('/')[1]
        obj.extension = ''
        obj.sort = ''
        obj.memo = ''
        resourceList.push(obj)
      })
      //   console.log(resourceGroupId, resourceList)
      await this.$store.dispatch('http', {
        api: 'resourceEdit',
        params: {
          resourceGroupId,
          resourceList
        },
        success: (e) => {
          this.switchFun(1)
          this.form.fileList = []
        },
        error: (e) => {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: e
          })
        }
      })
    },
    handleCancel (e) {
      if (this.status === 2) {
        this.switchFun(1)
      } else {
        this.visible = false
        this.showSelectedImages = []
      }
    },
    // 切换 图片管理   与上传图片
    switchFun (num) {
      this.getImageGroup()
      this.status = num
      switch (num) {
        case 1:
          this.title = '我的图片'
          break
        case 2:
          this.title = '上传图片'
          break
        default:
          break
      }
    },
    async getImageGroup () {
      const res = await this.$store.dispatch('http', {
        api: 'resourceGroupList',
        params: {
          pageNo: 1,
          pageSize: 100
        }
      })
      if (res) {
        this.imageGroupList = res
        this.form.group = res[0].id
        this.selectGroupToShowImages(0)
      }
    },
    async loadList () {
      const that = this
      const res = await this.$store.dispatch('http', {
        api: 'resourcePage',
        params: {
          resourceGroupId: this.showGroupId,
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize
        },
        error: (e) => {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: e
          })
        }
      })

      let listTemp = res && res.records ? res.records : res

      // 如果要显示图片的宽高
      if (this.showWidth) {
        let self = this
        let newArr = []
        listTemp.forEach(async function (element) {
          let imageInfo = await that.getImageSize(element.imgUrl)
          let widthHeightFit = false
          //   无宽高限制
          if (self.width === -1 && self.height === -1) {
            widthHeightFit = true
          } else if (self.width !== -1 && self.height !== -1) {
            widthHeightFit = self.width === imageInfo.width && self.height === imageInfo.height
          } else {
            widthHeightFit = self.width === imageInfo.width || self.height === imageInfo.height
          }
          //   有宽度限制
          let obj = { ...element, width: imageInfo.width, height: imageInfo.height, widthHeightFit: widthHeightFit }
          newArr.push(obj)
        })
        this.imagesOfGroup = newArr

        let inteval = setInterval(() => {
          if (newArr.length === listTemp.length) {
            this.setSelected()
            clearTimeout(inteval)
          }
        }, 200)
      } else {
        this.imagesOfGroup = listTemp
        this.setSelected()
      }
      this.pagination.total = res.total
    },
    // 设置哪些图片已经被选中
    setSelected () {
      console.log(this.showSelectedImages, this.imagesOfGroup, 'dfdffd')
      this.imagesOfGroup.forEach(element => {
        element.selected = false
        if (this.showSelectedImages.indexOf(element.imgUrl) !== -1) {
          element.selected = true
        }
      })
      this.$forceUpdate()
    },
    async selectGroupToShowImages (num) {
      this.imageGroupList.forEach((item, index) => {
        item.active = index === num ? 'active' : ''
      })
      this.showGroupId = this.imageGroupList[num].id
      this.pagination.pageNo = 1
      this.loadList()
      this.$forceUpdate()
    },
    uploadImages () {
      this.switchFun(2)
    },
    onSearch () {
      console.log('input search image group')
    }
  }
}
</script>

<style lang="scss" scoped>
.upload_disabled .ant-upload.ant-upload-disabled {
    cursor: pointer !important;
}

.my_images {
    display: flex;
    min-height:600px;

    .my_images_left {
        width: 230px;
    }

    .my_images_right {
        flex: 1;
        padding-left: 20px;

    }
}

.image_item_wrap {

    display: flex;
    flex-wrap: wrap;

    .image_item {
        position: relative;
        width: 105px;
        height: 105px;
        border: 1px solid rgb(200, 200, 200);
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%
        }
    }
}

.image_group_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    padding: 0 20px;

    >div:first-child {
        flex: 1;
    }

    >div:last-child {
        width: auto;
    }
}

.image_group_item.active {
    background: #eee;
}
.w_h_fit {
    background:rgb(11, 116, 46) !important;
}
</style>

<!-- 下面将展示比较全的组件写法 -->
<!--
<ImageManage v-model="form.imageUrl" @change="$refs.form.validateField(['imageUrl'])">
    <div slot="extra">建议图片800*450，jpg,png,gif，单张图片大小不超过2MB</div>
</ImageManage>

参数
v-model 绑定数据
square 是否是1:1
size 图片最大支持多少  bit number

事件
change

插槽
slot=extra
 -->
